.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.shuffle-container {
    width: 90%;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

.loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
  }